.modal-menu-nav-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;
    width: 180px;
    margin: auto;

    li {
        list-style-type: none;
        width: 100%;
        color: white;

        img {
            width: 24px;
        }

        a {
            padding: 10px 30px;
        }

        .menu-list-item {
            display: flex;
            gap: 15px;
            align-items: center;
            margin: auto;
        }


    }
}

.menu-nav-bars {
    display: flex;
    margin: 0;
    li {
        list-style-type: none;
        width: 100%;
        color: white;

        img {
            width: 24px;
        }

        a {
            z-index: 1;

            .menu-list-item {
                padding: 10px 30px;
                display: flex;
                gap: 15px;
                align-items: center;
                margin: auto;
                width: 80px;
                transition: all 0.2s ease-in-out;
            }

            img {
                height: 18px;
            }

            h4 {
                display: none;
                white-space: nowrap;
                font-size: 14px;
                font-family: var(--font-family);
                font-weight: normal;
            }
        }

        a:hover,
        a:active,
        a.active {
            .menu-list-item {
                width: 140px;
                background-color: var(--white-opacity);
            }

            h4 {
                display: block;
            }
        }

        a.expand-none {
            .menu-list-item {
                width: max-content;
                padding-left: 20px;
                padding-right: 20px;
                justify-content: flex-end;
            }
        }


        
    }

    span {

        // flex: 1;
        a {
            display: flex;
            justify-content: center;
            gap: 15px;
            padding: 10px 30px;
            z-index: 1;
        }


    }
}
@media only screen and (max-width: 576px) {
    .menu-nav-bars {
        li:not(.expand) {
            display: none;
        }
        li.expand {
            svg {
                font-size: 32px;
            }
        }
        
    }
}