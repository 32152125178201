.modal-menu-extra-wrapper {
  width: 100%;
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 120;
  .button-modal-close {
    position: absolute;
    top: 1em;
    right: 1.3em;
    font-size: 2em;
    z-index: 10;
  }
  nav.nav-modal-container {
    width: 100vw;
    height: 100vh;
  }
}
