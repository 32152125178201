.header-wrapper {
  width: 100%;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    span.logo {
      position: absolute;
      top: 1.5em;
      left: 3em;
      z-index: 99;
      animation-name: fadeInLeft;
      animation-duration: 400ms;
      img {
        height: 64px;
        filter: brightness(1);
      }
    }
    nav.nav-menu-container {
      position: absolute;
      top: 2.5em;
      right: 3em;
      z-index: 99;
      animation-name: fadeInRight;
      animation-duration: 400ms;
      
      .menu-option-bars {
        font-size: 25px;
      }
    }
  }
} // navbar menu

/** TABLET DESIGN */

@media only screen and (max-width: 992px) {
  .header-wrapper {
    .header-container {
      span.logo {
        top: 20px;
        left: 20px;
      }
      nav.nav-menu-container {
        top: 2.5em;
        right: 0px;
      }
    }
  }
}

/** MOBILE DESIGN */

@media only screen and (max-width: 576px) {
  .header-wrapper {
    .header-container {
      span.logo {
        top: 9px;
        left: 9px;
        position: fixed;
      }
      nav.nav-menu-container {
        top: 10px;
        right: 30px;
        position: fixed;
      }
    }
  }
}
