footer {
  color: var(--white);
  width: 0;
  height: 0;
  * {
    z-index: 1;
  }
  .left-footer-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    gap: 10px;
    bottom: 2.5em;
    left: 3em;
    z-index: 99;
    animation-name: fadeInLeft;
    animation-duration: 400ms;
    a {
      display: flex;
      align-items: center;
      gap: 1em;
      i, svg {
        width: 14px;
      }
    }
  }

  .right-footer-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    gap: 1em;
    bottom: 30px;
    right: 30px;
    z-index: 99;
    animation-name: fadeInRight;
    animation-duration: 400ms;
  }
}

/** TABLET DESIGN */

@media only screen and (max-width: 992px) {
  footer {
    .left-footer-container {
      bottom: 25px;
      right: 25px;
    }
    .right-footer-container {
      bottom: 25px;
      right: 25px;
    }
  }
}

/** MOBILE DESIGN */

@media only screen and (max-width: 576px) {
  footer {
    .left-footer-container {
      bottom: 1.5em;
      left: 1.5em;
      position: fixed;
    }
    .right-footer-container {
      bottom: 30px;
      right: 30px;
      position: fixed;
    }
  }
}
